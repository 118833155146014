import React from "react"
import {Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"

const ComboFlynow = () => {
  const data = {
    id: "combo_apps",
    title: "FLYNOW PRODUTIVIDADE + FLYNOW FINANÇAS PESSOAIS + FLYNOW FRASES",
    discontPercentage: 25,
    URL_CHECKOUT: "https://buy.stripe.com/4gwaGr01P5pX2gU7sQ?locale=pt-BR&prefilled_promo_code=FLYN25",
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVOS (PLANO PREMIUM VITALÍCIO)",
        data: Products.apps
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow